import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./console.css";
import { FaRocket } from "react-icons/fa";
import DynamicForm from './DynamicForm';
import Listings from "./listings";

const requiredParameters = {
    gcp: {
        "gcp-firestoreindatabasemode": [
            "firestore_database_id"
        ],
        "gcp-firestore": [
            "firestore_database_id"
        ],
        "gcp-monitoring": [
            "notification_email"
        ],
        "gcp-databricks": [
            "subnet_name",
            "ip_cidr_range"
        ],
        "gcp-dataflow": [
            "dataset"
        ],
        "gcp-cloudbuild": [
            "image_name"
        ],
        "gcp-vertexai": [
            "network_name"
        ],
        "gcp-cloudsql": [
            "database_name"
        ],
        "gcp-iam": [
            "role",
            "member",
        ],
        "gcp-bigtable": [
            'bigtable_instance_id',
            'bigtable_cluster_id'
        ],
        "gcp-coldline": [
            "bucket_name"
        ],
        'gcp-loadbalancer': [
            'backend_service_name'
        ],
        "gcp-persistantdisk": [
            "persistant_disk_name"
        ],
        "gcp-bigquery": [
            "dataset_id",
            "table_name",
        ],
        "gcp-pubsub": [
            "topic",
            "subscription",
        ],
        "gcp-kms": [
            "key_ring_name",
            "crypto_key_name",
        ],
        'gcp-cloudfunctions': [
            'bucket_name',
            'temp_gcs_bucket',
            'cloud_function_name'
        ],
        'gcp-computeengine': [
            'fire_wall_rule_name',
            'subnet_name',
            'network_name',
            'vm_name',
            'machine_type',
            'vm_image',
        ],
        'gcp-clouddns': [
            'dns_name',
            'zone_description',
            'zone_name'
        ],
        'gcp-vpc': ['network_name', 'subnet_name', 'ip_cidr_range'],
        'gcp-cloudarmor': [
            'instance_template_name_prefix',
            'machine_type',
            'network_name',
            'instance_group_manager_name',
            'instance_base_name',
            'target_size',
            'backend_service_name',
            'health_check_name',
            'security_policy_name'
        ],
        'gcp-datafusion': [
            'bucket',
            'bucket_name_suffix',
            'gcs_csv_file_path',
            'dataset_id',
            'table_id',
            'data_fusion_instance_name',
            'data_fusion_instance_type',
            'data_fusion_instance_version',
            'environment_label',
            'pipeline_name'
        ],
        'gcp-cloudspanner': [
            'zone_name',
            'account_id',
            'display_name',
            'db_name',
            'instance_name'
        ],
        'gcp-memorystore': [
            'bucket_name',
            'redis_instance_name',
            'redis_memory_size_gb',
            'network_name',
            'subnetwork_name',
            'subnetwork_ip_cidr_range',
            'service_account_id',
            'service_account_display_name'
        ],
        'gcp-appengine': [
            'bucket',
            'bucket_prefix',
            'app_bucket_suffix',
            'deployment_version',
            'app_engine_service',
            'app_engine_runtime',
            'app_engine_entrypoint'
        ],
        'gcp-kubernetes': [
            'cluster_name',
            'service_name',
            'initial_node_count',
            'location',
            'image',
            'container_port'
        ],
        'gcp-cloudrun': [
            'image_name',
            'service_name'
        ]
    },
    aws: {
        "aws-documentdb": [
            "vpc_cidr",
            "subnet_cidr_1",
            "subnet_cidr_2",
            "master_username",
            "master_password",
        ],
        "aws-pinpoint": [
            "application_name"
        ],
        "aws-outposts": [
            "vpc_cidr",
            "subnet_cidr"
        ],
        "aws-cloudformation": [
            "vpc_cidr",
            "subnet_cidr"
        ],
        "aws-sqs": [
            "queue_name"
        ],
        "aws-codepipeline": [
            "application_name",
            "deployment_group_name"
        ],
        "aws-databricks": [
            "vpc_cidr",
            "subnet_cidr"
        ],
        "aws-sagemaker": [
            "subnet_id"

        ],
        "aws-codebuild": [
            "codebuild_project_name"
        ],
        "aws-iotcore": [
            "vpc_cidr",
            "subnet_cidr"
        ],

        "aws-iam": [
            'iam_role_name',
            'iam_policy_name',
            'iam_policy_document',
        ],
        "aws-dynamodb": [
            "dynamodb_table_name",
        ],
        "aws-glacier": [
            "bucket_name"
        ],
        'aws-elasticloadbalancer': [
            'backend_service_name',
        ],
        "aws-elasticblockstorage": [
            "volume_name"
        ],
        "aws-kinesisstream": [
            "kinesis_stream_name",
            "shard_count",
        ],
        "aws-kms": [
            "key_name",
            "key_description"
        ],
        'aws-route53': ['hosted_zone_name'],
        'aws-lambda': [
            'bucket_name',
            'function_name',
            'runtime',
            // 'handler',
            // 'prefix_ignore'
        ],
        'aws-ec2': [
            'instance_name',
            'vpc_cidr',
            'subnet_cidr',
            'ami_id'
        ],
        'aws-vpc': ['vpc_cidr', 'subnet_cidr'],
        'aws-waf': ['rule_metric_name', 'acl_metric_name'],
        'aws-redshift': [
            'security_group_name',
            'security_group_description',
            'subnet_group_name',
            'cluster_identifier',
            'redshift_db_name',
            'master_username',
            'master_password',
            'node_type',
            'cluster_type',
            's3_bucket_name',
            's3_csv_file_name',
            'redshift_table',
            'iam_role_arn',
        ],
        'aws-rds': [
            'vpc_cidr',
            'availability_zones',
            // 'availability_zones',
            'subnet_cidrs',
            // "subnet_cidrs",
            'db_subnet_group_name',
            'security_group_name',
            'rds_ingress_port',
            // 'rds_ingress_cidr_blocks',
            'db_allocated_storage',
            'db_storage_type',
            'db_engine',
            'db_engine_version',
            'db_instance_class',
            'db_identifier',
            'db_username',
            'db_password',
            'db_parameter_group_name',
            'db_skip_final_snapshot',
        ],
        'aws-kubernetes': [
            'cluster_name_prefix',
            'vpc_name',
            'cidr',
            // 'private_subnets',
            // 'public_subnets',
            // 'cluster_version',
            // 'instance_types',
            // 'node_group_one',
            // 'node_group_two',
            // 'enable_nat_gateway',
            // 'single_nat_gateway',
            // 'enable_dns_hostnames',
            // 'public_subnet_tags',
            // 'private_subnet_tags',
        ],
        'aws-firegate': ['ecs_task_definition_name', 'ecs_service_name'],
        'aws-beanstalk': [
            'language',
            'instance_type',
            'app_name',
            'app_description',
        ],
    },
    azure: {
        "azure-sentinel": [
            "address_space",
            "ip_cidr_range",
            "log_analytics_workspace_name"
        ],
        "azure-policy": [
            "policy_assignment_name",
            "policy_name"
        ],
        "azure-devops": [
            "project-name"
        ],
        "azure-databricks": [
            "databricks_workspace_name",
            "location"
        ],
        "azure-synapseanalytics": [
            "prefix",
            "location",
            "sql_admin_username",
            "sql_admin_password",
        ],
        "azure-sqldatabases": [
            "location"
        ],
        "azure-spatial-anchors-accounts": [
            "location"
        ],
        "azure-machinelearning": [
            "tenant_id",
            "client_id",
        ],
        "azure-logicapps": [
            "location"
        ],
        "azure-iothub": [
            "prefix",
            "location",
        ],

        "azure-activedirectory": [
            "group_display_name",
            'resource_group_name',
            'location',
            'tenant_id',
        ],
        "azure-archivestorage": [
            "container_name"
        ],
        'azure-loadbalancer': [
            'backend_service_name'
        ],
        "azure-manageddisks": [
            "resource_group_name",
            "disk_name",
        ],
        "azure-cosmosdb": [
            "cosmosdb_resource_group_name",
            "cosmosdb_name",
        ],
        "azure-servicebus": [
            "service_bus_namespace_name",
            "queue_name",
            "topic_name",
            "subscription_name",
        ],
        "azure-keyvault": [
            "resource_group_name",
            "vault_name",
            "key_name",
        ],
        "azure-cdn": [
            "resource_group_name",
            "storage_account_name",
            "profile_name",
            "endpoint",
            "origin_name",
            "host_name",
            // 

            // "azure-keyvalut": "",
            // "resource_group_name": "zagit-keyvalut",
            // "vault_name": "zagit-keyvalut",
            // "key_name": "zagit-keyvalut",




            //   "resource_group_name": "harigcpwork",
            //   "storage_account_name": "harigcpvm",
            // 	"profile_name": "harigcpvm",
            // 	"endpoint": "harigcpvm",
            // 	"origin_name": "harigcpvm",
            // 	"host_name": "harigcpvm",
        ],
        'azure-vm': [
            'resource_group_name',
            // 'location',
            'vm_name',
            'admin_username',
            'admin_password',
            'vm_size',
        ],
        'azure-functions': [
            'resource_group_name',
            'storage_account_name',
            'app_service_plan_name',
            'function_app_name'
        ],
        'azure-trafficmanager': [
            'resource_group_name',
            'traffic_manager_profile_name',
            'traffic_manager_endpoint_name',
            // 'traffic_routing_method',
            'endpoint_fqdn',
            // 'endpoint_type'
        ],
        'azure-vpc': ['address_space', 'ip_cidr_range'],
        'application-gateway': [
            'resource_group_name',
            'public_ip_name',
            'app_gateway_name',
        ],
        'azure-rediscache': [
            'resource_group_name',
            'redis_capacity',
            'redis_family',
            'redis_sku_name',
            'redis_enable_non_ssl_port',
        ],
        'azure-kubernetes': [
            'cluster_name',
            'node_pool_name',
            'node_count',
            'vm_size',
            'identity_type',
        ],
        'azure-datafactory': [
            'resource_group_name',
            'storage_account_name',
            'container_name',
            'cosmosdb_account_name',
            'cosmosdb_database_name',
            'cosmosdb_container_name',
            'cosmosdb_partition_key_path',
            'data_factory_name',
            'csv_file_path',
            'cosmosdb_linked_service_name',
            'storage_linked_service_name',
            'pipeline_name',
            'source_container',
            'destination_container',
            'source_dataset',
            'destination_dataset',
        ],
        'azure-containerservice': [
            'resource_group_name',
            'container_name',
            'container_image',
            'container_cpu',
            'container_memory',
            'docker_username',
            'docker_password',
        ],
        'azure-appservice': [
            'tier',
            'app_service_plan_sku',
            'app_service_name',
            'app_service_plan_name',
            'resource_group_name',
        ],
    },
};
const getStatusClass = (status) => {
    switch (status) {
        case 'create-success':
            return 'success';
        case 'destroy-success':
            return 'status-destroy-success';
        case 'create-pending':
        case 'destroy-pending':
            return 'warning';
        case 'create-failure':
        case 'destroy-failure':
            return 'error';
        default:
            return '';
    }
};

const serviceDisplayNames = {
    "gcp-firestoreindatabasemode": "Gcp firestore in database mode",
    "gcp-firestore": "Gcp Firestore",
    'gcp-monitoring': 'Gcp Monitoring',
    'gcp-databricks': 'Gcp Databricks',
    'gcp-dataflow': 'Gcp Dataflow',
    'gcp-cloudbuild': 'Gcp cloud build',
    'gcp-vertexai': 'Gcp vertex ai',
    'gcp-cloudsql': 'Gcp cloud sql',
    "gcp-iam": "Gcp Iam",
    "gcp-bigtable": "Gcp Bigtable",
    "gcp-coldline": "Gcp Coldline",
    'gcp-loadbalancer': 'Gcp Load Balancer',
    "gcp-persistantdisk": "Gcp Persistant Disk",
    "gcp-bigquery": "Gcp Bigquery",
    "gcp-pubsub": "Gcp Pubsub",
    "gcp-kms": "Gcp Kms",
    'gcp-cloudfunctions': 'Gcp Cloudfunctions',
    'gcp-computeengine': 'Gcp Computeengine',
    'gcp-clouddns': 'Gcp Clouddns',
    'gcp-vpc': 'GCP VPC',
    'gcp-cloudarmor': 'GCP Cloud Armor',
    'gcp-datafusion': 'GCP Data Fusion',
    'gcp-cloudspanner': 'GCP Cloud Spanner',
    'gcp-memorystore': 'GCP Memorystore',
    'gcp-appengine': 'GCP App Engine',
    'gcp-kubernetes': 'GCP Kubernetes Engine',
    'gcp-cloudrun': 'GCP Cloud Run',


    "aws-documentdb": "AWS Document DB",
    "aws-pinpoint": "AWS Pinpoint",
    "aws-outposts": "AWS Outposts",
    "aws-cloudformation": "AWS Cloudformation",
    "aws-sqs": 'AWS SQS',
    'aws-codepipeline': 'AWS Codepipeline',
    "aws-databricks": "AWS Databricks",
    'aws-sagemaker': "AWS sagemaker",
    'aws-codebuild': "AWS codebuild",
    'aws-iotcore': "AWS iotcore",
    "aws-iam": "AWS IAM",
    'aws-dynamodb': 'Aws DynamoDB',
    'aws-glacier': `Aws Glacier`,
    'aws-elasticloadbalancer': "Aws Elastic Load Balancer",
    "aws-elasticblockstorage": "Aws Elastic Block Storage",
    "aws-kinesisstream": "Aws Kinesis Stream",
    "aws-kms": "Aws Kms",
    'aws-route53': 'Aws Route53',
    'aws-lambda': 'Aws Lambda',
    'aws-ec2': 'Aws Ec2',
    'aws-vpc': 'AWS VPC',
    'aws-waf': 'AWS WAF',
    'aws-redshift': 'AWS Redshift',
    'aws-rds': 'AWS RDS',
    'aws-kubernetes': 'AWS EKS',
    'aws-firegate': 'AWS Firegate',
    'aws-beanstalk': 'AWS Beanstalk',


    'azure-sentinel': 'Azure Sentinel',
    'azure-policy': 'Azure Policy',
    'azure-activedirectory': 'Azure Active Directory',
    'azure-archivestorage': "Azure Archive Storage",
    'azure-loadbalancer': "Azure Load Balancer",
    "azure-manageddisks": "Azure Managed Disks",
    "azure-cosmosdb": "Azure cosmosdb",
    "azure-servicebus": "Azure Service Bus",
    "azure-cdn": "Azure CDN",
    "azure-keyvault": "Azure Key Vault",
    'azure-vm': 'Azure Vm',
    'azure-functions': 'Azure Functions',
    'azure-trafficmanager': 'Azure Trafficmanager',
    'azure-vpc': 'Azure Virtual Network',
    'application-gateway': 'Azure Application Gateway',
    'azure-rediscache': 'Azure Redis Cache',
    'azure-kubernetes': 'Azure Kubernetes Service',
    'azure-datafactory': 'Azure Data Factory',
    'azure-containerservice': 'Azure Container Service',
    'azure-appservice': 'Azure Appservice',
};

const Newscreen = () => {

    const servicesByPlatform = {
        gcp: [
            'gcp-vpc',
            'gcp-cloudarmor',
            'gcp-datafusion',
            'gcp-cloudspanner',
            'gcp-memorystore',
            'gcp-appengine',
            'gcp-kubernetes',
            'gcp-cloudrun'
        ],
        aws: [
            'aws-vpc',
            'aws-waf',
            'aws-redshift',
            'aws-rds',
            'aws-kubernetes',
            'aws-firegate',
            'aws-beanstalk'
        ],
        azure: [
            'azure-vpc',
            'application-gateway',
            'azure-rediscache',
            'azure-kubernetes',
            'azure-datafactory',
            'azure-containerservice',
            'azure-appservice'
        ],
    };

    const [platformStates, setPlatformStates] = useState({
        gcp: {
            selectedServices: [],
            serviceStatus: {},
            loadingStatus: {},
            failureReasons: {},
            inputParams: {},
            infra: "create",
            etl: "create",
            prefix: "",
            services: [],
            logs: "",
            showLogs: false,
            sessionCount: 1,
            deployments: [],
            page: 1,
            totalPages: 1,
            loading: false,
            selectedValue: 'false',
            generatedName: "",
            activeTab: 'all',
            selectedPlatform: '',
            selectedInfra: '',
            selectedPrefix: '',
            sortConfig: { key: 'service', direction: 'asc' },
        },
        aws: {
            selectedServices: [],
            serviceStatus: {},
            loadingStatus: {},
            failureReasons: {},
            inputParams: {},
            infra: "create",
            etl: "create",
            prefix: "",
            services: [],
            logs: "",
            showLogs: false,
            sessionCount: 1,
            deployments: [],
            page: 1,
            totalPages: 1,
            loading: false,
            selectedValue: 'false',
            generatedName: "",
            activeTab: 'all',
            selectedPlatform: '',
            selectedInfra: '',
            selectedPrefix: '',
            sortConfig: { key: 'service', direction: 'asc' },
        },
        azure: {
            selectedServices: [],
            serviceStatus: {},
            loadingStatus: {},
            failureReasons: {},
            inputParams: {},
            infra: "create",
            etl: "create",
            prefix: "",
            services: [],
            logs: "",
            showLogs: false,
            sessionCount: 1,
            deployments: [],
            page: 1,
            totalPages: 1,
            loading: false,
            selectedValue: 'false',
            generatedName: "",
            activeTab: 'all',
            selectedPlatform: '',
            selectedInfra: '',
            selectedPrefix: '',
            sortConfig: { key: 'service', direction: 'asc' },
        },
    });


    const [currentPlatform, setCurrentPlatform] = useState("");

    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("🚀 ~ Newscreen ~ user:", user)

    // console.log("🚀 ~ Newscreen ~ user?.user_metadata?.name:", user?.user_metadata?.name, user?.user_metadata?.userName)

    const token = JSON.parse(localStorage.getItem("app-access-token"));

    const generaterandomname = (username) => {
        const randomId = Math.random().toString(36).substr(2, 4);
        return `${username}-${randomId}`
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, "")
            .substr(0, 63);
    };


    useEffect(() => {
        if (currentPlatform && platformStates[currentPlatform]) {
            setPlatformStates((prevStates) => ({
                ...prevStates,
                [currentPlatform]: {
                    ...prevStates[currentPlatform],
                    selectedServices: prevStates[currentPlatform].selectedServices || [],
                },
            }));
        }
    }, [currentPlatform]);


    const handlePlatformChange = (platform) => {
        setCurrentPlatform(platform);

        setPlatformStates((prevStates) => ({
            ...prevStates,
            [platform]: {
                ...prevStates[platform],
                selectedServices: prevStates[platform].selectedServices || [],
            },
        }));
    };


    const handleInputChange = (platform, field, value) => {
        setPlatformStates(prevStates => ({
            ...prevStates,
            [platform]: {
                ...prevStates[platform],
                [field]: value,
            }
        }));
    };

    const handleCheckboxChange = (platform, srv) => {
        setPlatformStates((prevStates) => {
            const isSelected = prevStates[platform].selectedServices.includes(srv);
            const updatedSelectedServices = isSelected
                ? prevStates[platform].selectedServices.filter((service) => service !== srv)
                : [...prevStates[platform].selectedServices, srv];

            return {
                ...prevStates,
                [platform]: {
                    ...prevStates[platform],
                    selectedServices: updatedSelectedServices,
                },
            };
        });
    };


    const getServiceButtonClass = (platform, srv) => {
        const { loadingStatus, serviceStatus } = platformStates[platform];
        if (loadingStatus[srv] === "running") return "service-btn-running";

        switch (serviceStatus[srv]) {
            case "success":
                return "service-btn-success";
            case "failure":
                return "service-btn-failure";
            default:
                return "service-btn-idle";
        }
    };

    // Function to generate serviceParameters based on platform and its state
    const generateServiceParameters = (platform) => {
        const state = platformStates[platform];
        const generatedName = state.generatedName;
        return {
            "gcp-firestoreindatabasemode": {
                firestore_database_id: "(default)"
            },
            "gcp-firestore": {
                firestore_database_id: "(default)"
            },
            "gcp-monitoring": {
                notification_email: `${generatedName}-notification_email`
            },
            "gcp-databricks": {
                subnet_name: `${generatedName} -globalcloud-databricks-subnet`,
                ip_cidr_range: "10.0.0.0/24"
            },
            "gcp-dataflow": {
                dataset: `${generatedName}-zagit-dataset`
            },

            "gcp-cloudbuild": {
                image_name: `${generatedName}-image-name`
            },
            "gcp-vertexai": {
                network_name: `${generatedName}-globalcloud-mode-network`
            },
            "gcp-cloudsql": {
                database_name: `${generatedName}-zagit-database-name`
            },
            "gcp-iam": {
                role: `role`,
                member: `${generatedName}-member`
            },
            "gcp-bigtable": {
                bigtable_instance_id: `${generatedName}-zagit-bigtable`,
                bigtable_cluster_id: `${generatedName}-zagit-cluster`
            },

            "gcp-coldline": {
                bucket_name: `${generatedName}-zagit-coldname`
            },
            'gcp-loadbalancer': {
                'backend_service_name': `${generatedName}-backend-service-name`,
            },
            "gcp-persistantdisk": {
                "persistant_disk_name": `${generatedName}-zagit-disk-name`,
            },
            "gcp-bigquery": {
                dataset_id: `${generatedName}-gcp-data-set`,
                table_name: `${generatedName}-gcp-table-id`,
            },
            "gcp-pubsub": {
                topic: `${generatedName}-zagit-topic`,
                subscription: `${generatedName}-zagit-topic`,
            },
            "gcp-kms": {
                "key_ring_name": `${generatedName}-zagitio-keyring`,
                "crypto_key_name": `${generatedName}-zagito-cryptokey`,
            },
            'gcp-cloudfunctions': {
                bucket_name: `${generatedName}-bucket-name`,
                temp_gcs_bucket: `${generatedName}-temp-gcs-bucket`,
                cloud_function_name: `${generatedName} cloud-function-name`
            },
            'gcp-computeengine': {
                fire_wall_rule_name: `${generatedName}-allow-ssh-zagit`,
                subnet_name: `${generatedName}-com-engine-zag`,
                network_name: `${generatedName}-zagitcomutename`,
                vm_name: "flask-vm",
                machine_type: "f1-micro",
                vm_image: "debian-cloud/debian-11"
            },
            'gcp-clouddns': {
                dns_name: `${generatedName}-example-dns-name`,
                zone_description: `${generatedName}-Example DNS zone`,
                zone_name: `${generatedName}-example-zone-name`
            },
            "gcp-vpc": {
                network_name: `${generatedName}-vpc-network`,
                subnet_name: `${generatedName}-vpc-subnet`,
                ip_cidr_range: "10.19.0.0/16",
            },
            "gcp-cloudarmor": {
                instance_template_name_prefix: `${generatedName}-zagit-name`,
                machine_type: "n1-standard-1",
                network_name: "default",
                instance_group_manager_name: `${generatedName}-igm`,
                instance_base_name: `${generatedName}-base-name`,
                target_size: "2",
                backend_service_name: `${generatedName}-backend-service`,
                health_check_name: `${generatedName}-health-check`,
                security_policy_name: `${generatedName}-security-policy`,
            },
            'gcp-datafusion': {
                bucket: "my-datafusion-bucket",
                bucket_name_suffix: "df-suffix",
                gcs_csv_file_path: "gs://my-datafusion-bucket/data.csv",
                data_fusion_instance_name: "my-data-fusion-instance",
                data_fusion_instance_type: "BASIC",

                dataset_id: 'xyz',
                table_id: 'xyz',
                data_fusion_instance_version: 'xyz',
                environment_label: 'xyz',
                pipeline_name: 'xyz',
            },
            'gcp-cloudspanner': {
                zone_name: "us-central1-f",
                account_id: "my-svc-account",
                display_name: "my-svc-account",
                db_name: "my-database",
                instance_name: "my-instance"
            },
            'gcp-memorystore': {
                bucket_name: "globalcloud-cloudfunctions",
                redis_instance_name: "my-instance",
                redis_memory_size_gb: "1",
                network_name: "memeorystore-globalcloud-001",
                subnetwork_name: "memeorystore-subnet-001",
                subnetwork_ip_cidr_range: "10.0.0.0/16",
                service_account_id: "my-sa-memorystore",
                service_account_display_name: "My Service Account"
            },

            'gcp-appengine': {
                bucket: "ts-appengine-terraform-tfstate",
                bucket_prefix: "ts-appengine-terraform/state",
                app_bucket_suffix: "rplayer",
                deployment_version: "v1",
                app_engine_service: "default",
                app_engine_runtime: "nodejs20",
                app_engine_entrypoint: "node index.js"


            },
            'gcp-kubernetes': {
                cluster_name: "gke-standard-regional-cluster",
                service_name: "example-hello-app-loadbalancer",
                initial_node_count: "1",
                location: "us-central1",
                image: "us-docker.pkg.dev/google-samples/containers/gke/hello-app:2.0",
                container_port: "8080"
            },
            'gcp-cloudrun': {
                image_name: "d-my-app",
                service_name: "d-my-service",
            },

            // aws
            "aws-documentdb": {
                vpc_cidr: "10.0.0.0/16",
                subnet_cidr_1: "10.0.3.0/24",
                subnet_cidr_2: "10.0.2.0/24",
                master_username: "globalcloud",
                master_password: "Saani12345"
            },
            "aws-pinpoint": {
                application_name: `${generatedName}-application_name`
            },
            "aws-outposts": {
                vpc_cidr: "10.0.0.0/16",
                subnet_cidr: "10.0.0.0/24"
            },
            "aws-cloudformation": {
                vpc_cidr: "10.0.0.0/16",
                subnet_cidr: "10.0.0.0/24"
            },
            "aws-sqs": {
                queue_name: `${generatedName}-queue_name`,
            },
            "aws-codepipeline": {
                application_name: `${generatedName}-zagit-application_name`,
                deployment_group_name: `${generatedName}-zagit-deployment_group_name`
            },
            "aws-databricks": {
                vpc_cidr: "10.0.0.0/16",
                subnet_cidr: "10.0.0.0/24"
            },
            "aws-sagemaker": {
                subnet_id: "subnet-0188ff6f456a821ba"
            },
            "aws-codebuild": {
                codebuild_project_name: `${generatedName}-codebuild-project-name`
            },
            "aws-iotcore": {
                vpc_cidr: "10.0.0.0/16",
                subnet_cidr: "10.0.1.0/24"
            },
            'aws-iam': {
                iam_role_name: `${generatedName}-example-iam-role`,
                iam_policy_name: `${generatedName}-example-iam-policy-name`,
                iam_policy_document: `${generatedName}-iam-policy-document`
            },
            "aws-dynamodb": {
                dynamodb_table_name: `${generatedName}-zagit-dynamodb_table_name`
            },
            "aws-glacier": {
                bucket_name: `${generatedName}-zagit-glacier`
            },
            'aws-elasticloadbalancer': {
                'backend_service_name': `${generatedName}-backend-service-name`,
            },
            "aws-elasticblockstorage": {
                "volume_name": `${generatedName}-zagit-storage`
            },
            "aws-kinesisstream": {
                kinesis_stream_name: `${generatedName}-my-stream`,
                shard_count: "1",
            },
            "aws-kms": {
                key_name: `${generatedName}-my-kmszag`,
                key_description: `${generatedName}-my-kmszag`
            },
            'aws-route53': {
                hosted_zone_name: `${generatedName}-zagit.io`,
            },
            'aws-lambda': {
                bucket_name: `${generatedName}-globalcloud-etl`,
                function_name: `${generatedName}-sample-lambda`,
                runtime: 'python3.8',
                // handler: 'lambda_function.lambda_handler',
                // prefix_ignore: 'runtime',
            },
            'aws-ec2': {
                instance_name: `${generatedName}-my-instance`,
                vpc_cidr: '10.0.0.0/16',
                subnet_cidr: '10.0.1.0/24',
                ami_id: 'ami-0e1c5d8c23330dee3',
            },
            "aws-vpc": {
                vpc_cidr: "10.19.0.0/16",
                subnet_cidr: "10.0.1.0/24",
            },
            "aws-waf": {
                rule_metric_name: `${generatedName} -waf - rule - metric`,
                acl_metric_name: `${generatedName} -acl - metric`,
            },


            'aws-redshift': {
                security_group_name: "redshift-sg",
                security_group_description: "Redshift Security Group",
                subnet_group_name: "new-example1",
                cluster_identifier: "my-redshift-cluster",
                redshift_db_name: "mydatabase",
                master_username: "globalcloud",
                master_password: "Saani12345",
                node_type: "dc2.large",
                cluster_type: "single-node",
                s3_bucket_name: "gabriel-aws-redshift",
                s3_csv_file_name: "sample.csv",
                redshift_table: "my_table",
                iam_role_arn: "arn:aws:iam::792196125483:role/aws"
            },
            'aws-rds': {
                vpc_cidr: "10.0.0.0/16",
                // availability_zones: ["us-east-1a", "us-east-1b"]
                availability_zones: "us-east-1a",
                // availability_zones: "us-east-1b",
                // subnet_cidrs: ["10.0.1.0/24", "10.0.2.0/24"],
                subnet_cidrs: "10.0.1.0/24",
                // subnet_cidrs: "10.0.2.0/24",
                db_subnet_group_name: "globalcloud-rds-mysql",
                security_group_name: "rds-sg",
                rds_ingress_port: "3306",
                rds_ingress_cidr_blocks: "0.0.0.0/0",
                db_allocated_storage: "20",
                db_storage_type: "gp2",
                db_engine: "mysql",
                db_engine_version: "5.7",
                db_instance_class: "db.t3.micro",
                db_identifier: "mydb",
                db_username: "example",
                db_password: "examplepassword",
                db_parameter_group_name: "default.mysql5.7",
                db_skip_final_snapshot: true
            },
            'aws-kubernetes': {
                cluster_name_prefix: "education-eks",
                vpc_name: "education-vpc",
                cidr: "10.0.0.0/16",

                // private_subnets: ["10.0.1.0/24", "10.0.2.0/24", "10.0.3.0/24"],
                // public_subnets: ["10.0.4.0/24", "10.0.5.0/24", "10.0.6.0/24"],
                // cluster_version: "1.29",
                // instance_types: ["t3.small"],
                // node_group_one: { "min_size": 1, "max_size": 3, "desired_size": 2 },
                // node_group_two: { "min_size": 1, "max_size": 2, "desired_size": 1 },
                // enable_nat_gateway: true,
                // single_nat_gateway: true,
                // enable_dns_hostnames: true,
                // public_subnet_tags: { "kubernetes.io/role/elb": 1 },
                // private_subnet_tags: { "kubernetes.io/role/internal-elb": 1 }
            },
            'aws-firegate': {
                ecs_task_definition_name: "my-task-defination",
                ecs_service_name: "my_service",
            },
            'aws-beanstalk': {
                language: "Node.js 18",
                instance_type: "t2.micro",
                app_name: "globalcloud-node-app",
                app_description: "app description"

            },

            // azure
            "azure-sentinel": {
                address_space: "10.0.0.0/16",
                ip_cidr_range: "10.0.1.0/24",
                log_analytics_workspace_name: `${generatedName}-log-analytics-workspace`
            },
            "azure-policy": {
                policy_assignment_name: `${generatedName}-example-policy-assignment`,
                policy_name: `${generatedName}-example-policy`
            },
            "azure-devops": {
                project_name: `${generatedName}-project-name`
            },
            "azure-databricks": {
                databricks_workspace_name: `${generatedName}-zagit-databricks`,
                location: `westus`
            },
            "azure-synapseanalytics": {
                prefix: "tfex",
                location: "eastus",
                sql_admin_username: "adminuser",
                sql_admin_password: "P@ssw0rd1234!"
            },
            "azure-sqldatabases": {
                sql_server_name: `${generatedName}-zagit-sql-server`,
                sql_database_name: `${generatedName}-zagit-sql-database`,
                sql_admin_username: `${generatedName}-zagit`,
                sql_admin_password: `${generatedName}-zagit12!`,
                resource_group_name: `${generatedName}-zagit-sql-resource-group`,
            },
            "azure-spatial-anchors-accounts": {
                location: `westus`
            },
            "azure-machinelearning": {
                tenant_id: `${generatedName}-tenant_id`,
                client_id: `${generatedName}-client_id`,
            },
            "azure-logicapps": {
                location: `location`
            },
            "azure-iothub": {
                prefix: `${generatedName}-tfex`,
                location: `westus`

            },
            "azure-activedirectory": {
                group_display_name: `${generatedName}-group-display-name`,
                resource_group_name: `${generatedName}-resource-group-name`,
                location: `location`,
                tenant_id: `${generatedName}-tenant-id`
            },
            "azure-archivestorage": {
                container_name: `${generatedName}-zagit-archive`
            },
            'azure-loadbalancer': {
                "backend_service_name": `${generatedName}-backend-service-name`,
            },
            "azure-manageddisks": {
                "resource_group_name": `${generatedName}-zagit-cosmosdb`,
                "disk_name": `${generatedName}-zagit-cosmosdb`
            },
            "azure-cosmosdb": {
                cosmosdb_resource_group_name: `${generatedName}-zagit-cosmosdb`,
                cosmosdb_name: `${generatedName}-zagit-cosmosdb`
            },
            "azure-servicebus": {
                service_bus_namespace_name: `${generatedName}-zagit-keyvault`,
                queue_name: `${generatedName}-zagit-keyvault`,
                topic_name: `${generatedName}-zagit-servicebus`,
                subscription_name: `${generatedName}-zagit-servicebus`,
            },
            "azure-keyvault": {
                resource_group_name: `${generatedName}-zagit`,
                vault_name: `${generatedName}-zagit`,
                key_name: `${generatedName}-zagit`,
            },
            "azure-cdn": {
                resource_group_name: `${generatedName}-harigcpwork`,
                storage_account_name: `${generatedName}-harigcpvm`,
                profile_name: `${generatedName}-harigcpvm`,
                endpoint: `${generatedName}-harigcpvm`,
                origin_name: `${generatedName}-harigcpvm`,
                host_name: `${generatedName}-harigcpvm`,
            },
            'azure-vm': {
                resource_group_name: `${generatedName}-harigcpwork`,
                // location: 'westus',
                vm_name: `${generatedName}-harigcpvm`,
                admin_username: 'harigcp',
                admin_password: 'Harigcp12!@',
                vm_size: 'Standard_DS1_v2',
            },
            'azure-functions': {
                resource_group_name: `${generatedName}-harigcp001`,
                storage_account_name: `${generatedName}-harigcp001`,
                app_service_plan_name: `${generatedName}-harigcp001`,
                function_app_name: `${generatedName}-harigcp001`
            },
            'azure-trafficmanager': {
                resource_group_name: 'hari001-resources',
                traffic_manager_profile_name: 'hari001-tm-profile',
                traffic_manager_endpoint_name: 'hari001-tm-endpoint',
                // traffic_routing_method: 'Performance',
                endpoint_fqdn: 'hari001.com',
                // endpoint_type: 'ExternalEndpoint'
            },
            "azure-vpc": {
                vpc_cidr: "10.19.0.0/16",
                subnet_cidr: "10.1.1.0/24",
            },
            "application-gateway": {
                resource_group_name: `${generatedName} -my - resource - group`,
                public_ip_name: `${generatedName} -my - public - ip`,
                app_gateway_name: `${generatedName} -my - app - gateway`,
            },



            'azure-rediscache': {
                resource_group_name: "rg-example1111",
                redis_capacity: "0",
                redis_family: "C",
                redis_sku_name: "Basic",
                redis_enable_non_ssl_port: false
            },
            'azure-kubernetes': {
                resource_group_name: "rg-example",
                cluster_name: "example-aks_azure",
                node_pool_name: "default",
                node_count: 1,
                vm_size: "Standard_DS2_v2",
                identity_type: "SystemAssigned"
            },
            'azure-datafactory': {
                resource_group: "rg-example",
                storage_account_name: "stgacctexample",
                container_name: "example-container",
                cosmosdb_account_name: "cosmosdbgcp",
                cosmosdb_database_name: "cosmosdbexampledb",
                cosmosdb_container_name: "cosmosdbexamplecontainer",
                cosmosdb_partition_key_path: "/Index",
                data_factory_name: "adfexampleglobalcloud",
                csv_file_path: "sample.csv",
                cosmosdb_linked_service_name: "CosmosDBService",
                storage_linked_service_name: "StorageService",
                pipeline_name: "CopyPipeline",
                source_container: "example-container",
                destination_container: "cosmosdbexamplecontainer",
                source_dataset: "BlobDataset",
                destination_dataset: "CosmosDBDataset"
            },
            'azure-containerservice': {
                resource_group_name: "rg-example",
                container_name: "mycontainerinstance",
                container_image: "nginx:latest",
                container_cpu: "1",
                container_memory: "1.5",
                docker_username: "alaskaerp",
                docker_password: "UmerSinghera786!@",
                resource_group_name: "resource_group_name"
            },
            'azure-appservice': {
                tier: "Basic",
                app_service_plan_sku: "B1",
                app_service_name: "myAppServiceglobalhari",
                app_service_plan_name: "myAppServicePlan",
                resource_group_name: "myAppServiceRG"
            },
        };
    };

    // Handle parameter changes
    const handleParamChange = (platform, event, service, sessionIndex) => {
        const { name, value } = event.target;
        setPlatformStates(prevStates => ({
            ...prevStates,
            [platform]: {
                ...prevStates[platform],
                inputParams: {
                    ...prevStates[platform].inputParams,
                    [sessionIndex]: {
                        ...prevStates[platform].inputParams[sessionIndex],
                        [service]: {
                            ...(prevStates[platform].inputParams[sessionIndex]?.[service] || {}),
                            [name]: value
                        }
                    }
                }
            }
        }));
    };

    // Handle session count change
    const handleSessionCountChange = (platform, e) => {
        const value = e.target.value;
        setPlatformStates(prevStates => ({
            ...prevStates,
            [platform]: {
                ...prevStates[platform],
                sessionCount: value,
            }
        }));
    };



    // Handle selected value change
    const handleSelectedValueChange = (platform, value) => {
        setPlatformStates(prevStates => ({
            ...prevStates,
            [platform]: {
                ...prevStates[platform],
                selectedValue: value,
                generatedName: value === "true"
                    ? generaterandomname(user?.user_metadata?.name || user?.user_metadata?.userName)
                    : prevStates[platform].generatedName,
            }
        }));
    };

    // Fetch services when platform or token changes
    useEffect(() => {
        if (currentPlatform) {
            setPlatformStates((prevStates) => ({
                ...prevStates,
                [currentPlatform]: {
                    ...prevStates[currentPlatform],
                    logs: "",
                    failureReasons: {},
                    serviceStatus: prevStates[currentPlatform].serviceStatus || {},
                    loadingStatus: prevStates[currentPlatform].loadingStatus || {},
                    infra: prevStates[currentPlatform].infra || "create",
                },
            }));

            fetch(
                `${process.env.REACT_APP_BASE_URL_MAIN}/services?platform=${currentPlatform}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setPlatformStates((prevStates) => ({
                        ...prevStates,
                        [currentPlatform]: {
                            ...prevStates[currentPlatform],
                            services: data.services,
                            // Set selectedServices to all services if it hasn't been selected before
                            selectedServices: prevStates[currentPlatform].selectedServices?.length
                                ? prevStates[currentPlatform].selectedServices
                                : data.services,
                            serviceStatus: prevStates[currentPlatform].serviceStatus || data.services.reduce(
                                (acc, srv) => ({ ...acc, [srv]: "idle" }),
                                {}
                            ),
                            loadingStatus: prevStates[currentPlatform].loadingStatus || data.services.reduce(
                                (acc, srv) => ({ ...acc, [srv]: "idle" }),
                                {}
                            ),
                            infra: prevStates[currentPlatform].infra || "create",
                        },
                    }));
                })
                .catch((error) => {
                    toast.error(`Error fetching services: ${error.message}`);
                });
        } else {
            setPlatformStates((prevStates) => ({
                ...prevStates,
                gcp: { ...prevStates.gcp, services: [] },
                aws: { ...prevStates.aws, services: [] },
                azure: { ...prevStates.azure, services: [] },
            }));
        }
    }, [currentPlatform, token]);


    // Handle generatedName based on selectedValue

    useEffect(() => {
        if (currentPlatform && platformStates[currentPlatform].selectedValue === "true") {
            const generatedName = generaterandomname(user?.user_metadata?.name || user?.user_metadata?.userName);

            // Only update platformStates if generatedName is different
            if (platformStates[currentPlatform].generatedName !== generatedName) {
                setPlatformStates(prevStates => ({
                    ...prevStates,
                    [currentPlatform]: {
                        ...prevStates[currentPlatform],
                        generatedName
                    }
                }));
            }
        }
    }, [currentPlatform, platformStates, user]);




    const handleDeploy = async (platform, e) => {
        e.preventDefault();

        const state = platformStates[platform];

        if (state.selectedServices.length === 0) {
            toast.error("Please select at least one service before deploying.");
            return;
        }

        setPlatformStates(prevStates => ({
            ...prevStates,
            [platform]: {
                ...prevStates[platform],
                logs: "",
                failureReasons: {},
            }
        }));

        const deployPromises = [];
        const serviceParameters = generateServiceParameters(platform); // Define serviceParameters here
        const excludePrefixServices = ["gcp-kms", "aws-kms", "azure-keyvault"];


        for (let i = 0; i < state.sessionCount; i++) {
            state.selectedServices.forEach((service) => {

                const serviceRequiredParams = requiredParameters[platform]?.[service] || [];
                const specificParams = {
                    ...serviceParameters[service],
                    ...state.inputParams[i]?.[service]
                };
                const payload = {
                    infra: state.infra,
                    input_from_user: state.selectedValue === "true",
                    user: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    username: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    sessionCount: state.sessionCount,
                    type: "asynchronous",
                    ...specificParams,
                };

                // Exclude `prefix` for specific services
                if (!excludePrefixServices.includes(service)) {
                    payload.prefix = state.prefix;
                }
                // Optional: Validate required fields
                // const missingParams = serviceRequiredParams.filter(param => !payload[param]);
                // if (missingParams.length > 0) {
                //     toast.error(`Missing required parameters for ${service}: ${missingParams.join(', ')}`);
                //     return;
                // }

                setPlatformStates(prevStates => ({
                    ...prevStates,
                    [platform]: {
                        ...prevStates[platform],
                        loadingStatus: {
                            ...prevStates[platform].loadingStatus,
                            [service]: "running",
                        }
                    }
                }));

                deployPromises.push(
                    fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy/${platform}/${service}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(payload),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            // console.log("🚀 ~ .then ~ data:", data);
                            const { message, status, error, details, deploymentId } = data;

                            if (message === "Deployment created successfully") {
                                toast.success(
                                    `${service} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                                );
                                setPlatformStates(prevStates => ({
                                    ...prevStates,
                                    [platform]: {
                                        ...prevStates[platform],
                                        serviceStatus: {
                                            ...prevStates[platform].serviceStatus,
                                            [service]: "success",
                                        }
                                    }
                                }));
                            } else if (error) {
                                setPlatformStates(prevStates => ({
                                    ...prevStates,
                                    [platform]: {
                                        ...prevStates[platform],
                                        serviceStatus: {
                                            ...prevStates[platform].serviceStatus,
                                            [service]: "failure",
                                        },
                                        failureReasons: {
                                            ...prevStates[platform].failureReasons,
                                            [service]: details || ["Unknown error occurred."],
                                        }
                                    }
                                }));

                                toast.error(
                                    `${service} deployment failed: ${details || error} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                                );
                            } else {
                                console.error(`Unexpected status from ${service}: ${status}`);
                                toast.error(`Unexpected status from ${service}: ${status}`);
                            }

                            setPlatformStates(prevStates => ({
                                ...prevStates,
                                [platform]: {
                                    ...prevStates[platform],
                                    loadingStatus: {
                                        ...prevStates[platform].loadingStatus,
                                        [service]: "idle",
                                    }
                                }
                            }));
                        })
                        .catch((error) => {
                            console.error("Error in deployment:", error);
                            setPlatformStates(prevStates => ({
                                ...prevStates,
                                [platform]: {
                                    ...prevStates[platform],
                                    serviceStatus: {
                                        ...prevStates[platform].serviceStatus,
                                        [service]: "failure",
                                    },
                                    loadingStatus: {
                                        ...prevStates[platform].loadingStatus,
                                        [service]: "idle",
                                    },
                                    failureReasons: {
                                        ...prevStates[platform].failureReasons,
                                        [service]: ["Network error occurred."],
                                    }
                                }
                            }));

                            toast.error(`${service} deployment failed due to network error.`);
                        })
                );
            });
        }

        await Promise.all(deployPromises);
    };

    const handleDeploySynchronous = async (platform, e) => {
        e.preventDefault();

        const state = platformStates[platform];

        if (state.selectedServices.length === 0) {
            toast.error("Please select at least one service before deploying.");
            return;
        }

        setPlatformStates(prevStates => ({
            ...prevStates,
            [platform]: {
                ...prevStates[platform],
                logs: "",
                failureReasons: {},
            }
        }));

        const serviceParameters = generateServiceParameters(platform); // Define serviceParameters here

        for (let i = 0; i < state.sessionCount; i++) {
            for (const service of state.selectedServices) {

                const serviceRequiredParams = requiredParameters[platform]?.[service] || [];
                const specificParams = {
                    ...serviceParameters[service],
                    ...state.inputParams[i]?.[service]
                };
                const payload = {
                    infra: state.infra,
                    input_from_user: state.selectedValue === "true",
                    user: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    username: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    sessionCount: state.sessionCount,
                    type: "Synchronous",
                    ...specificParams,
                };

                // Optional: Validate required fields
                // const missingParams = serviceRequiredParams.filter(param => !payload[param]);
                // if (missingParams.length > 0) {
                //     toast.error(`Missing required parameters for ${service}: ${missingParams.join(', ')}`);
                //     return;
                // }

                setPlatformStates(prevStates => ({
                    ...prevStates,
                    [platform]: {
                        ...prevStates[platform],
                        loadingStatus: {
                            ...prevStates[platform].loadingStatus,
                            [service]: "running",
                        }
                    }
                }));

                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy/${platform}/${service}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(payload),
                    });

                    const data = await response.json();
                    // console.log("🚀 ~ .then ~ data:", data);
                    const { message, status, error, details, deploymentId } = data;

                    if (message === "Deployment created successfully") {
                        toast.success(
                            `${service} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                        );
                        setPlatformStates(prevStates => ({
                            ...prevStates,
                            [platform]: {
                                ...prevStates[platform],
                                serviceStatus: {
                                    ...prevStates[platform].serviceStatus,
                                    [service]: "success",
                                }
                            }
                        }));
                    } else if (error) {
                        setPlatformStates(prevStates => ({
                            ...prevStates,
                            [platform]: {
                                ...prevStates[platform],
                                serviceStatus: {
                                    ...prevStates[platform].serviceStatus,
                                    [service]: "failure",
                                },
                                failureReasons: {
                                    ...prevStates[platform].failureReasons,
                                    [service]: details || ["Unknown error occurred."],
                                }
                            }
                        }));

                        toast.error(
                            `${service} deployment failed: ${details || error} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                        );
                    } else {
                        console.error(`Unexpected status from ${service}: ${status}`);
                        toast.error(`Unexpected status from ${service}: ${status}`);
                    }

                } catch (error) {
                    console.error("Error in deployment:", error);
                    setPlatformStates(prevStates => ({
                        ...prevStates,
                        [platform]: {
                            ...prevStates[platform],
                            serviceStatus: {
                                ...prevStates[platform].serviceStatus,
                                [service]: "failure",
                            },
                            failureReasons: {
                                ...prevStates[platform].failureReasons,
                                [service]: ["Network error occurred."],
                            }
                        }
                    }));

                    toast.error(`${service} deployment failed due to network error.`);
                } finally {
                    setPlatformStates(prevStates => ({
                        ...prevStates,
                        [platform]: {
                            ...prevStates[platform],
                            loadingStatus: {
                                ...prevStates[platform].loadingStatus,
                                [service]: "idle",
                            }
                        }
                    }));
                }
            }
        }
    };

    const handleDeleteDeploy = (deployment) => {
        const payload = {
            service: deployment.service,
            infra: "destroy",
            prefix: deployment.prefix,
            sessionCount: deployment.sessionCount,
            platform: deployment.platform,
            user: deployment.user,
            username: deployment.username,
            deploymentId: deployment.deploymentId
        };

        fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy/${deployment.platform}/${deployment.service}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                const { message, status, deploymentId } = data;

                if (status === "success") {
                    toast.success(
                        message
                            ? `${message} (ID: ${deploymentId})`
                            : `${deployment.service} delete service started successfully!`
                    );

                    // Refresh deployments
                    fetchDeployments();
                } else {
                    toast.error(
                        message
                            ? `${message} (ID: ${deploymentId})`
                            : `${deployment.service} delete service failed!`
                    );
                }
            })
            .catch((error) => {
                console.error("Deletion failed due to network error:", error);
                toast.error(`${deployment.service} delete service failed due to network error.`);
            });
    };












    const allServices = Object.entries(servicesByPlatform).flatMap(([platform, services]) =>
        services.map(service => ({
            service,
            displayName: serviceDisplayNames[service] || service,
        }))
    );



    const getAllDeployments = () => {
        let allDeployments = [];
        Object.keys(platformStates).forEach(platform => {
            allDeployments = [...allDeployments, ...platformStates[platform].deployments];
        });
        return allDeployments;
    };





    // Handle input changes for filters
    const handleFilterChange = (filterType, value) => {
        // This function will trigger data fetching based on filter changes
        if (filterType === 'activeTab') {
            setFilterState(prev => ({ ...prev, activeTab: value, page: 1 }));
        } else if (filterType === 'selectedPlatform') {
            setFilterState(prev => ({ ...prev, selectedPlatform: value, page: 1 }));
        } else if (filterType === 'selectedInfra') {
            setFilterState(prev => ({ ...prev, selectedInfra: value, page: 1 }));
        }
    };

    // Filter state for Listings component
    const [filterState, setFilterState] = useState({
        activeTab: 'all',
        selectedPlatform: '',
        selectedInfra: '',
        sortConfig: { key: 'Sr', direction: 'asc' },
        page: 1,
        totalPages: 1,
    });

    // Combined list of deployments
    const combinedDeployments = getAllDeployments();

    // Function to sort deployments based on sortConfig
    const sortedDeployments = () => {
        const sorted = [...combinedDeployments];
        const { key, direction } = filterState.sortConfig;

        sorted.sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        return sorted;
    };

    // Function to filter deployments based on filterState
    const filteredDeployments = () => {
        let filtered = sortedDeployments();

        const { activeTab, selectedPlatform, selectedInfra } = filterState;

        if (selectedPlatform) {
            filtered = filtered.filter(deployment => deployment.platform === selectedPlatform);
        }

        if (selectedInfra) {
            filtered = filtered.filter(deployment => deployment.infra === selectedInfra);
        }

        if (activeTab && activeTab !== 'all') {
            if (Object.keys(serviceDisplayNames).includes(activeTab)) {
                filtered = filtered.filter(deployment => deployment.service === activeTab);
            } else {
                filtered = filtered.filter(deployment => deployment.status === activeTab);
            }
        }

        return filtered;
    };

    // Handle sorting  
    const handleSort = (key) => {
        let direction = 'asc';
        if (filterState.sortConfig.key === key && filterState.sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setFilterState(prev => ({ ...prev, sortConfig: { key, direction } }));
    };

    // Handle page change
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= filterState.totalPages) {
            setFilterState(prev => ({ ...prev, page: newPage }));
        }
    };

    // Combine all unique services across platforms for filter buttons
    const allServicesUnique = Array.from(new Set(allServices.map(s => s.service))).map(service => ({
        service,
        displayName: serviceDisplayNames[service] || service,
    }));


    // Function to fetch deployments based on current filters
    const fetchDeployments = () => {
        const { activeTab, selectedPlatform, selectedInfra, sortConfig, page } = filterState;
        const queryParams = new URLSearchParams();
        queryParams.append("limit", 100);
        queryParams.append("page", page);
        queryParams.append("username", user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'));

        if (activeTab && activeTab !== 'all') {
            if (Object.keys(serviceDisplayNames).includes(activeTab)) {
                queryParams.append("service", activeTab);
            } else {
                queryParams.append("status", activeTab);
            }
        }

        if (selectedPlatform) {
            queryParams.append("platform", selectedPlatform);
        }

        if (selectedInfra) {
            queryParams.append("infra", selectedInfra);
        }

        setPlatformStates(prevStates => ({
            ...prevStates,
            [currentPlatform]: {
                ...prevStates[currentPlatform],
                loading: true,
            }
        }));

        fetch(`${process.env.REACT_APP_BASE_URL}/deploy?${queryParams.toString()}`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const groupedDeployments = data.results.reduce((acc, deployment) => {
                    const platform = deployment.platform;
                    if (!acc[platform]) acc[platform] = [];
                    acc[platform].push(deployment);
                    return acc;
                }, {});

                setPlatformStates(prevStates => {
                    const updatedPlatforms = {};
                    Object.keys(groupedDeployments).forEach(platform => {
                        updatedPlatforms[platform] = {
                            ...prevStates[platform],
                            deployments: groupedDeployments[platform], // **Replace** deployments
                            loading: false,
                        };
                    });
                    return { ...prevStates, ...updatedPlatforms };
                });

                setFilterState(prev => ({
                    ...prev,
                    totalPages: data.totalPages || 1,
                }));
            })
            .catch((error) => {
                toast.error(`Error fetching deployments: ${error.message}`);
                setPlatformStates(prevStates => ({
                    ...prevStates,
                    [currentPlatform]: {
                        ...prevStates[currentPlatform], // Corrected line
                        loading: false,
                    }
                }));
            });
    };



    // Fetch deployments when filterState changes
    useEffect(() => {
        if (currentPlatform) {
            fetchDeployments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState.activeTab, filterState.selectedPlatform, filterState.selectedInfra, filterState.page, currentPlatform]);


    return (
        <div className="tabs_screen_main_div">
            <div className="tabs_img_box">
                <div className="tabs_flex_box">
                    <Button
                        eventKey="azure"
                        title="Azure"
                        onClick={() => handlePlatformChange("azure")}
                        className={currentPlatform === "azure" ? "active-tab" : ""}
                    >
                        <img src="/images/azure-icon.png" alt="Azure" />
                    </Button>

                    <Button
                        eventKey="aws"
                        title="AWS"
                        onClick={() => handlePlatformChange("aws")}
                        className={currentPlatform === "aws" ? "active-tab" : ""}
                    >
                        <img src="/images/aws-icon.png" alt=" AWS" />
                    </Button>
                    <Button
                        eventKey="gcp"
                        title="GCP"
                        onClick={() => handlePlatformChange("gcp")}
                        className={currentPlatform === "gcp" ? "active-tab" : ""}
                    >
                        <img src="/images/gcp-icon.png" alt="GCP" />
                    </Button>

                </div>

                <div className="tabs_content_div">
                    <div className="container-fluid" style={{ height: '100%' }} >
                        <div className="tabs-content" style={{ height: '100%' }}>
                            <div className="sidebar_content" style={{ height: '100%', overflowY: 'auto' }}>
                                <div className="pb-5 grid-container">
                                    {currentPlatform && platformStates[currentPlatform].services.map((srv, index) => (
                                        <div
                                            key={index}
                                            className="text-center mb-3"
                                            draggable
                                            onDragStart={(e) =>
                                                e.dataTransfer.setData("service", srv)
                                            }
                                            onDragEnd={() => handleCheckboxChange(currentPlatform, srv)}
                                        >
                                            <Button
                                                className={`Process-cards ${getServiceButtonClass(currentPlatform, srv)}`}
                                                onClick={() => handleCheckboxChange(currentPlatform, srv)}
                                            >
                                                <div className="img_box" style={{ minHeight: "40px" }}>
                                                    <Form.Check
                                                        aria-label={`option-${index}`}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={() => handleCheckboxChange(currentPlatform, srv)}
                                                        checked={platformStates[currentPlatform]?.selectedServices?.includes(srv) || false}
                                                        id={`option-${index}`}
                                                    />


                                                    <img
                                                        src={`/images/${srv}.png`}
                                                        alt={srv}
                                                        className="service-img"
                                                    />
                                                </div>

                                                <h5 className="service-text">
                                                    {srv}{" "}
                                                    {platformStates[currentPlatform].loadingStatus[srv] === "running" && "Running..."}
                                                </h5>
                                            </Button>
                                        </div>
                                    ))}
                                </div>

                                {currentPlatform && platformStates[currentPlatform].selectedServices.length > 0 && (
                                    <div className="dynamic-form-section">
                                        {platformStates[currentPlatform].selectedServices.map((srv) => (
                                            <div key={`form-${srv}`}>
                                                <h3>{`Configure ${currentPlatform.toUpperCase()} - ${srv}`}</h3>

                                                {Array.from({ length: platformStates[currentPlatform].sessionCount }).map((_, sessionIndex) => (
                                                    <div key={`form-${srv}-${sessionIndex}`}>
                                                        <h4>{`Session ${sessionIndex + 1}`}</h4>
                                                        <DynamicForm
                                                            setInputParams={(params) => setPlatformStates(prevStates => ({
                                                                ...prevStates,
                                                                [currentPlatform]: {
                                                                    ...prevStates[currentPlatform],
                                                                    inputParams: {
                                                                        ...prevStates[currentPlatform].inputParams,
                                                                        ...params
                                                                    }
                                                                }
                                                            }))}
                                                            platform={currentPlatform}
                                                            service={srv}
                                                            sessionIndex={sessionIndex}
                                                            inputParams={platformStates[currentPlatform].inputParams}
                                                            handleParamChange={(e) =>
                                                                handleParamChange(currentPlatform, e, srv, sessionIndex)
                                                            }
                                                            selectedValue={platformStates[currentPlatform].selectedValue}
                                                            username={user?.user_metadata?.name || user?.user_metadata?.userName}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="deployment_flex">
                                {/* Deployment logs and errors can be managed similarly within platformStates */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                currentPlatform && platformStates[currentPlatform].selectedServices.length > 0 && (
                    <div
                        className="btns_deploy"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            margin: "50px auto 20px",
                        }}
                    >

                        {/* <label htmlFor={`sessionCount-${currentPlatform}`}>Session Count: </label>
                        <input
                            type="number"
                            id={`sessionCount-${currentPlatform}`}
                            value={platformStates[currentPlatform].sessionCount}
                            onChange={(e) => handleSessionCountChange(currentPlatform, e)}
                        /> */}
                        <label htmlFor={`sessionCount-${currentPlatform}`}>Session Count: </label>
                        <select
                            id={`sessionCount-${currentPlatform}`}
                            value={platformStates[currentPlatform].sessionCount}
                            onChange={(e) => handleSessionCountChange(currentPlatform, e)}
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                        <div>
                            <label htmlFor={`dropdown-${currentPlatform}`}>Input From User</label>
                            <select
                                id={`dropdown-${currentPlatform}`}
                                value={platformStates[currentPlatform].selectedValue}
                                onChange={(e) => handleSelectedValueChange(currentPlatform, e.target.value)}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <Button
                            className="btn btn-dark m-2"
                            onClick={(e) => handleDeploy(currentPlatform, e)}
                            title="Deploy"
                            disabled={Object.values(platformStates[currentPlatform].loadingStatus).includes("running")}
                        >
                            <FaRocket />
                            Deploy (Parallel)

                        </Button>

                        <Button
                            className="btn btn-dark m-2"
                            onClick={(e) => handleDeploySynchronous(currentPlatform, e)}
                            title="Deploy"
                            disabled={Object.values(platformStates[currentPlatform].loadingStatus).includes("running")}

                        >
                            <FaRocket />
                            Deploy (Step by Step)

                        </Button>

                    </div>
                )
            }

            {/* Listing */}
            <Listings
                deployments={filteredDeployments()}
                serviceDisplayNames={serviceDisplayNames}
                loading={platformStates[currentPlatform]?.loading || false}
                page={filterState.page}
                totalPages={filterState.totalPages}
                sortConfig={filterState.sortConfig}
                handleSort={handleSort}
                handlePageChange={handlePageChange}
                handleDeleteDeploy={handleDeleteDeploy}
                activeTab={filterState.activeTab}
                allServices={allServicesUnique}
                selectedPlatform={filterState.selectedPlatform}
                selectedInfra={filterState.selectedInfra}
                handleFilterChange={handleFilterChange}
                getStatusClass={getStatusClass}
            />
        </div >
    );
};

export default Newscreen;
